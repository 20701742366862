<template>
  <div>
    <!-- 信息块 -->
    <div class="info flex-row-default-center">
      <!-- 图片 -->
      <img v-if="userInfo.userInfo && userInfo.userInfo.headimgurl !== ''" :src="userInfo.userInfo.headimgurl"/>
      <img v-else src="@/assets/images/avatar.png"/>

      <!-- 信息 -->
      <div class="flex-column">
        <!-- 公司名 -->
        <div v-if="userInfo.isMember" class="company-name flex-row-default-center">
          <span class="iconfont icon-company"></span>
          <span>{{ userInfo.companyName }}</span>
        </div>
        <!-- 用户名、会员等级 -->
        <div class="user-info flex-row-default-center">
          <span>{{ userInfo.name !== '' ? userInfo.name : userInfo.userInfo.nickname }}</span>
          <span class="member-grade" v-if="userInfo.memberGrade && userInfo.memberGrade.name !== ''">{{ userInfo.memberGrade.name }}</span>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项 -->
      <div class="list-item flex-row-between-center" @click="$router.push('/user/info')">
        <div class="flex-row-default-center">
          <span class="iconfont icon-user"></span>
          <span>个人信息</span>
        </div>
        <span class="iconfont icon-right-arrow"></span>
      </div>

      <!-- 列表项 -->
      <div class="list-item flex-row-between-center" @click="$router.push('/user/member')">
        <div class="flex-row-default-center">
          <span class="iconfont icon-member"></span>
          <span>会员信息</span>
        </div>
        <span class="iconfont icon-right-arrow"></span>
      </div>

      <!-- 列表项 -->
<!--      <div class="list-item flex-row-between-center" @click="$router.push('/user/certificate')">-->
<!--        <div class="flex-row-default-center">-->
<!--          <span class="iconfont icon-certificate"></span>-->
<!--          <span>相关证书</span>-->
<!--        </div>-->
<!--        <span class="iconfont icon-right-arrow"></span>-->
<!--      </div>-->

      <!-- 列表项 -->
      <div class="list-item flex-row-between-center" @click="$router.push('/user/activity-list')">
        <div class="flex-row-default-center">
          <span class="iconfont icon-activity"></span>
          <span>活动记录</span>
        </div>
        <span class="iconfont icon-right-arrow"></span>
      </div>

      <!-- 列表项 -->
      <div v-if="userInfo.isMember" class="list-item flex-row-between-center" @click="$router.push('/user/member-exchange-info-list')">
        <div class="flex-row-default-center">
          <span class="iconfont icon-chat"></span>
          <span>信息交流</span>
        </div>
        <span class="iconfont icon-right-arrow"></span>
      </div>
    </div>

    <!-- 按钮 -->
<!--    <div class="button-one" @click="handleLogout">退出登录</div>-->
  </div>
</template>

<script>
export default {
  name: 'PersonalCenter',

  data () {
    return {
      userInfo: {}
    }
  },

  beforeCreate () {
    const images = [
      require('@/assets/images/avatar.png')
    ]
    for (const src of images) {
      const image = new Image()
      image.src = src
    }
  },

  created () {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.userInfo = userInfo
    } else {
      this.getUserInfo()
    }
  },

  methods: {
    // 获取数据
    getUserInfo () {
      this.api.getUserInfo().then(res => {
        this.userInfo = res.data
        this.$store.commit('setUserInfo', this.userInfo)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getUserInfo() })
      })
    },
    // 退出登录
    handleLogout () {
      this.$store.commit('clearAll')
      // this.$store.commit('clearAccessToken')
    }
  }
}
</script>

<style scoped>
.info {
  background: #fff;
  padding: 15px 25px;
}
.info img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
}

.company-name {
  font-size: 15px;
  font-weight: bold;
}
.icon-company {
  font-weight: normal;
  margin-right: 5px;
}

.user-info {
  margin-top: 10px;
  font-size: 15px;
}
.member-grade {
  padding: 1px 5px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
}

.list {
  width: 100%;
  padding: 0 25px;
  margin-top: 15px;
  background: #fff;
}
.list-item {
  padding: 15px 10px;
  min-height: 60px;
}
.list-item + .list-item {
  border-top: 1px solid #eeeeee;
}
.list-item > div:first-child > span {
  margin-left: 20px;
  font-size: 15px;
}
.list-item > div:first-child > span:first-child {
  margin: 0;
  font-size: 19px;
  color: var(--main-color);
}

.list-item > span {
  color: #aaa;
  font-size: 13px;
}
</style>
